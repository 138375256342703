.clasturas__carousel-container {
    display:flex;
		align-items: center;
		height:100%;
    padding-left: 8px;
    padding-right: 8px;
		padding-bottom: 64px;
		margin-top: auto;
		margin-bottom: auto;
}

.claustras__title-section {
	margin-top: 32px;
	text-align: center;

	h2 {
		color: white;
		font-size: 28px;
		font-style: bold;
		font-weight: 700;
	}

	label {
		color: white;
		font-size: 20px;
	}
}

.claustras__description-section {
	margin: 48px 0px 24px 0px;
	label {
		color: white;
		text-align: justify;
		font-size: 16px;
	}
}