.store-detail-link {
    font-size: 1em;
    color: white;
    padding-top: 10em;
}
.store-detail-link:hover {
    color: white;
    text-decoration: none;
}

.store-detail-demande-btn {
    padding-left: 3em;
    padding-right: 3em;
    font-size: 1.5em;
    margin-top: 0.5em
}

.store-detail_carousel-container {
    display:flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    height:100%;
    padding-bottom: 75px;
}