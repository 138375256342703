.img-thumbnail {
    padding: 0.05em;
    border-radius: 0;
    width: 100%;
    height: 18em;
    object-fit: cover;
}
.store-image-container {
    margin-bottom: 2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.store-image-text {
    font-size: 1.2em;
    color: white;
    text-align: center;
    margin-top: 1em;
}

.store-title {
    text-align: center;
    font-size: 3em;
    color: white;
    margin-top: 2em;
}

.store-text {
    font-size: 1.2em;
    text-align: justify;
    opacity:0.7;
    color: white;
    margin-top: 2em;
}