.contact-text-title {
    color: white;
    text-align: center;
    font-size: 3em;
}

.contact-text-panel {
    color: white;
    text-align: center;
    font-size: 1.2em;
    margin: 1em;
}

.contact-container {
    margin-top: 8em;
}

.contact-form-row {
    margin-left:auto;
    margin-right:auto;
}

.contact-form-input {
    color: white;
    border: 1px solid transparent;
    background-color: rgba(237, 237, 237, 0.18);
    width:100%;
    margin: 0.2em;
}
.contact-form-input:focus {
    background-color:  rgba(236, 227, 227, 0.7);
    outline: 0;
    color: white;
    outline-width: 0;
}

.contact-form-input-invalid:focus {
    outline-width: 0;
}
.contact-form-input-invalid {
    color: white;
    border: 1px solid red;
    background-color: rgba(237, 237, 237, 0.18);
    width:100%;
    margin: 0.2em;
  }
.contact-form-submit {
    border: none;
    margin: 0.2em;
}
.contact-form-submit:hover {
    color: white;
    background-color: teal;
 }
