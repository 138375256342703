@import 'styles/typography.scss';
.realisations-photogallery-section {
    max-width: 1200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8em;
}

.realisations-photogallery-text {
    color:white;
    text-align: center;
    font-family: $fontPTSans;
    margin-bottom: 2.5em;
    .realisations-photogallery-text-title {
        font-size: 48px;
        color: white;
    }
    .realisations-photogallery-text-text {
        font-size: 21px;
        opacity: 0.9;
    }
}

.realisations-photogallery-gallery {
	.ReactGridGallery{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
 	}
}

