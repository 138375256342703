@import 'styles/typography.scss';

.header-nav  {
  background-color: rgba(0,0,0,0.5);
  font-size: 17px;
  padding: 0;
  transition: .5s ease opacity;
}

.nav-link {
  font-size: 17px;
  color: white;
}
.dropdown-menu {
  background-color: rgba(0,0,0,0.5);
  font-size: 1em;
}

.dropdown-item{
  color:white;
}

.dropdown-item:hover{
  background-color: rgba(0,0,0,0.5);
  color:grey;
}

.header-img{
  object-position:50% 50%;
  width:132px;
  //height:68px;
  object-fit:cover
}

.header__button {
  margin-left: 32px;
  font-size: 15px;
}

.nav-link, .nav-item{
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding: 0;
}

.dropdown-toggle:after {
  content: none;
}

.hide-navbar {
  opacity: 0;
}

.header-nav-container {
  max-width: 1400px;
}
