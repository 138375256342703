
.home-font2 {
    font-size: 3em;
    // line-height:1.3em;
    text-align:center;
    color: white;
    font-style: bold;
    font-weight: 600;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
}

.home-text1 {
    font-size: 21px;
    text-align:center;
    color: #ffffffaa;
}
.home-btn-en {
    font-size: 21px;
    margin-top: 1em;
    padding-left: 3em;
    padding-right: 3em;
}
.bar {
    height: 200px;
}