$fontPTSans: PT Sans, sans-serif;
$fontPTSansNarrow: PT Sans Narrow, sans-serif;
$fontRochester: Rochester, sans-serif;

$fontFamilies: (
  default: $fontPTSans,
  narrow: $fontPTSansNarrow,
  rochester: $fontRochester
);

$typography-breakpoints: (xs: 8px, sm: 10px, md: 12px, md-1: 14px, lg: 16px, lg-1: 18px, xl: 20px, xl-1: 24px, xl-2: 28px, xxl: 32px, xxl-1: 36px, xxl-2: 40px, xxl-3: 44px);
$lineheight-breakpoints: (xs: 10px, sm: 14px, md: 16px, md-1: 18px, lg: 20px, lg-1: 22px, xl: 24px, xl-1: 28px, xl-2: 32px, xxl: 36px, xxl-1: 42px, xxl-2: 44px, xxl-3: 48px);

@function get-fontsize($fontBreakpoint, $breakpoint: 'xxl') {
  @return map-get($typography-breakpoints, $fontBreakpoint);
}

@function get-lineheight($fontBreakpoint, $breakpoint: 'xxl') {
  @return map-get($lineheight-breakpoints, $fontBreakpoint);
}

@mixin typography($fontBreakpoint, $fontType: inherit, $fontWeight: normal) {
  font-style: normal;

  @if map-has-key($fontFamilies, $fontType) {
    font-family: map-get($fontFamilies, #{$fontType});
  }
  font-size: map-get($typography-breakpoints, $fontBreakpoint);
  line-height: map-get($lineheight-breakpoints, $fontBreakpoint);
  font-weight: $fontWeight;
}
