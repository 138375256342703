.sommes-container{
    max-width: 1100px;
    margin-top: 6em;
    margin-bottom: 2em;
    text-align: center;
}

.sommes-title {
    text-align: center;
    font-size: 3em;
    color: white;
}

.sommes-title-des {
    text-align: center;
    font-size: 1.1em;
    color: white;
}

.sommes-text-text {
    font-size: 1.2em;
    text-align: justify;
    color: white;
    margin-top: 1em;
}

.sommes-btn {
    margin-top: 3em;
    padding-right: 1em;
    padding-left: 1em;
    font-size: x-large;
}
