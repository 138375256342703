@import 'styles/typography.scss';

.home-ourservice-item {
    text-align: center;
    color:white;
}

.home-ourservice__image {
    padding: 12px;
}

.home-ourservice-row{
    margin: 6em;
}

.home-ourservice-item-title {
    @include typography(xl-2, none);
    margin-top: 24px;
}

.home-ourservice-item-text {
    @include typography(xl, none);
    opacity: 0.8;
}