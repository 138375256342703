
.component-parallax {
    text-align: center;
}
.component-parallax-section {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.component-parallax_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.component-parallax_text_section{
    color: white;
    overflow: hidden;
    text-align: center;
    margin-top: 2em;
}

.component-parallax_text {
    position: relative;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    font-size: 42px; 
    font-weight: 700;
}

@keyframes ani_text_fade_in {
    0%   { right: -400px; }
    100%  { right: 0px; }
}
