@import 'styles/typography.scss';
.references-image {
    height: 220px;
    width: 200px;
    width: 100%;
    padding: 8px 0px;
}
.references-title {
    font-size: 2.5em;
    color: white;
    text-align: center;
}

.references-container {
    max-width:1000px;
    margin-top: 8em;
    margin-bottom: 3em;
}