.faq-container{
    max-width: 1000px;
    margin-top: 6em;
    margin-bottom: 2em;
}
.faq-title {
    text-align: center;
    font-size: 3em;
    color: white;
    width: 100%;
}

.faq-title-des {
    text-align: center;
    font-size: 1.2em;
    color: white;
}
.faq-text-title {
    font-size: 1.5em;
    color: white;
    margin-top: 2em;
}
.faq-text-text {
    font-size: 1em;
    text-align: justify;
    color: white;
    margin-top: 1em;
}