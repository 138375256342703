.divider-horizontal-dotted {
    border-top: 3px dotted rgba(255, 255, 255, 0.6);
}
.footer-contact-text{
    font-size: 20px;
    text-align: left;
    color: white;
}

.footer-container{
    max-width: 1200px;
    padding-top: 2em;
    padding-bottom: 4em;
}

.footer-contact-img{
    margin-bottom: 1em;
    width:130px;
    //height:80px;
    object-fit:cover
}

.footer-link {
    font-size: 21px;
    color: white;
    opacity: 0.8;
}
.footer-link:hover {
    color: white;
    text-decoration: none;
}

.footer-link-section {
    border-right: 2px dotted rgba(255, 255, 255, 0.6);
    border-left: 2px dotted rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    margin-top: 2em;
    @media (max-width: 800px) {
        border-right: none;
        border-left: none;
    }
}

.footer-contact-section {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.footer-social-section {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.footer-social-img-section{
    padding-left: 3em;
    margin-bottom: 2em;
}

.footer-copyright-text{
    margin: 2em;
    text-align: center;
    font-size: 16px;
    color: white;
}

