.enseign__container {
  padding: 2em 12em 2em 12em;
}

.enseigne__title {
  text-align: justify;
  font-size: 1em;
  color: white;
  margin-top: 2em;
}
.enseigne__item__img {
  width:100%;
  height:20em;
  object-fit:cover;
}

.enseigne__item__title {
  font-size:1.2em;
  color:white;
  text-align:center;
  margin-top:1em;
}

