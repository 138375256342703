
.home-barparallax-section {
    width: 100%;
    height: 400px;
    min-height: 300px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.home-barparallax-bar, .home-barparallax-rest {
    min-height: 450px;
    position: relative;
    background-attachment: fixed;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 75% 100%;
}

.home-barparallax-bar {
    background-image: url("https://static.wixstatic.com/media/e49d9d_12a9650996c542cda2f3c50f62e4b130~mv2_d_6016_4016_s_4_2.jpg/v1/fill/w_609,h_430,al_c,q_85,usm_0.66_1.00_0.01/e49d9d_d4fe0feb3b3f4c63a10598969ea5838e~mv2.webp");
}

.home-barparallax-rest {
    background-image: url("https://static.wixstatic.com/media/e49d9d_3ac66eb9c38d4b7c88e97d451becf40d~mv2_d_6016_4016_s_4_2.jpg/v1/fill/w_609,h_430,al_c,q_85,usm_0.66_1.00_0.01/e49d9d_d4fe0feb3b3f4c63a10598969ea5838e~mv2.webp");
}

.home-barparallax__row {
    margin-top: 4em;
}

.home-barparallax__container {
    align-items:center;
    margin: auto;

    overflow: hidden;
}

.home-barparallax-text {
    font-size: 26px;
    line-height:1.5em;
    text-align:center;
    color: white;
    height: 100%;
    margin: auto;
    padding: 24px;
    opacity: 0.9;
}
.barparallax-img {
    transform: translateY(-25%);
}
