.pergola__description {
    font-size: 15px;
    color: white;
    opacity: 0.8;
    text-align: justify;
    margin-top: 2em;
}

.pergola__title {
    text-align: center;
    font-size: 3em;
    color: white;
    margin-top: 2em;
}
.pergola__item__img {
    width:100%;
    height:20em;
    object-fit:cover;
}

.pergola__item__title {
    font-size:1.2em;
    color:white;
    text-align:center;
    margin-top:1em;
}